
.leaflet-layer,
  // .leaflet-control-zoom-in,
  // .leaflet-control-zoom-out,
  // .leaflet-control-attribution
{
  // filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  // filter: brightness(40%) contrast(90%);
  filter: brightness(50%) contrast(100%);
}
